module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HMCC Ann Arbor Gatsby site","short_name":"hmccaa-web","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"90314e6941b5b4c969311874e2b3b25d"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Montserrat","file":"https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"},{"name":"Raleway","file":"https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap"},{"name":"Inter","file":"https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"},{"name":"Roboto","file":"https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"},{"name":"Ubuntu","file":"https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap"},{"name":"Bebas Neue","file":"https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"exclude":["**/dev-404-page/**","**/404/**","**/404.html","**/offline-plugin-app-shell-fallback/**"],"excludeOptions":{"separator":"."},"crumbLabelUpdates":[{"pathname":"/about","crumbLabel":"About"},{"pathname":"/about/our-team","crumbLabel":"Our Team"},{"pathname":"/about/hmi","crumbLabel":"HMI"},{"pathname":"/new","crumbLabel":"I'm New"},{"pathname":"/new/life-stages","crumbLabel":"Life Stages"},{"pathname":"/watch","crumbLabel":"Watch"},{"pathname":"/watch/sermons","crumbLabel":"Sermons"},{"pathname":"/get-involved","crumbLabel":"Next Steps"},{"pathname":"/get-involved/lifegroups","crumbLabel":"Life Groups"},{"pathname":"/get-involved/membership","crumbLabel":"Membership"},{"pathname":"/get-involved/transformation","crumbLabel":"Transformation Classes"},{"pathname":"/get-involved/baptism","crumbLabel":"Baptism"},{"pathname":"/get-involved/ministryteams","crumbLabel":"Ministry Teams"},{"pathname":"/get-involved/missions","crumbLabel":"Missions Support"},{"pathname":"/events","crumbLabel":"Events"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
